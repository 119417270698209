// eventHandlers.ts

import { Dispatch } from 'redux'
import {
  addMessagePrivetChat,
  changeFullPrivateChats,
  deleteMessagePrivetChats,
  markMessageAsRead,
  setChatTyping,
  setMessagePrivetChat,
  setMessagesSelectedId,
  setMessagesSelectedUser,
} from 'store/reducer/messages/reducer'
import { addNotification, incrementUnreadCount } from 'store/reducer/notifications/reducer'
import { setAccountClient, setAccountController } from 'store/reducer/accountController/reducer'
import { changeSocketEventGift, setSubscribe, setNotificationSocket } from 'store/reducer/socket/reducer'
import { popUpInitState, popUpOpen, setPopUpData } from 'store/reducer/popUp/reducer'
import { AccountResponse, StaffViewDto } from 'store/apiMain/mainApi'
import { setFriendsBoolean } from 'store/reducer/friends/reducer'
import { IUserRole } from './types'
import { EEventType } from '../hooks/useSoundPlayer'
import { setClientsToChangeRelationship } from 'store/reducer/clients/reducer'
import { setRoom } from 'store/reducer/rooms/reducer'
import { deleteClient, deleteModel } from 'store/reducer/user/reducer'

interface EventHandlersParams {
  dispatch: Dispatch<any>
  userMeRef: React.MutableRefObject<any>
  roleRef: React.MutableRefObject<IUserRole>
  messagesSelectedIdRef: React.MutableRefObject<string | null>
  getClickRef: React.MutableRefObject<boolean>
  navigate: any
  accountRef: React.MutableRefObject<AccountResponse | null>
  handleCreateRoom: (userId: string) => void
  playSound: (eventType: EEventType) => Promise<void>
  stopAllSounds: () => void
}

export const handleEventData = (
  eventData: any,
  {
    dispatch,
    userMeRef,
    roleRef,
    messagesSelectedIdRef,
    getClickRef,
    navigate,
    handleCreateRoom,
    playSound,
    stopAllSounds,
    accountRef,
  }: EventHandlersParams,
) => {
  switch (eventData.type) {
    case 'ACTIVITY_STATUS': {
      dispatch(setSubscribe(eventData))
      break
    }

    case 'NOTIFICATION': {
      dispatch(setNotificationSocket(eventData))
      dispatch(addNotification(eventData.body))
      dispatch(incrementUnreadCount())
      if (eventData.body.type === 'GIFT_RECEIVED') {
        dispatch(changeSocketEventGift())
        playSound('gift')
      } else if (eventData.body.type === 'ADD_FAVORITES') {
        playSound('addFavorites')
      }
      // Добавьте другие типы уведомлений и соответствующие звуки при необходимости
      break
    }

    case 'CHAT_TYPING_NOTIFICATION': {
      const data = {
        ...eventData,
        body: {
          ...eventData.body,
          isActive: true,
        },
      }
      dispatch(setChatTyping(data))
      // Обычно уведомления о наборе текста не требуют звука
      break
    }

    case 'OTHER': {
      handleOtherEvent(eventData, {
        dispatch,
        navigate,
        userMeRef,
        roleRef,
        messagesSelectedIdRef,
        getClickRef,
        playSound,
        stopAllSounds,
        accountRef,
      })
      break
    }

    case 'CALL': {
      handleCallEvent(eventData, {
        dispatch,
        roleRef,
        navigate,
        handleCreateRoom,
        playSound,
        stopAllSounds,
      })
      break
    }

    default:
      // Обработка неизвестных типов событий при необходимости
      break
  }
}

interface HandleOtherEventParams {
  dispatch: Dispatch<any>
  navigate: any
  userMeRef: React.MutableRefObject<any>
  roleRef: React.MutableRefObject<IUserRole>
  messagesSelectedIdRef: React.MutableRefObject<string | null>
  getClickRef: React.MutableRefObject<boolean>
  playSound: (eventType: EEventType) => Promise<void>
  stopAllSounds: () => void
  accountRef: React.MutableRefObject<AccountResponse | null>
}

const handleOtherEvent = (
  eventData: any,
  { dispatch, navigate, userMeRef, roleRef, messagesSelectedIdRef, getClickRef, playSound, stopAllSounds, accountRef }: HandleOtherEventParams,
) => {
  switch (eventData.subType) {
    case 'ACCOUNT_BALANCE_UPDATED': {
      // @ts-ignore
      if (userMeRef?.current?.model?.id === eventData.body?.userId) {
        dispatch(setAccountController(eventData.body))
      }
      if (roleRef.current === 'CLIENT') {
        if (accountRef?.current) {
          if (eventData?.body?.coins > accountRef?.current?.coins) {
            playSound('upBalance')
          }
        }
        // if (getClickRef.current) {
        //   playSound('upBalance')
        // }
      }
      if (
        // @ts-ignore
        userMeRef?.current?.model?.id !== eventData.body?.userId &&
        roleRef.current === 'MODEL'
      ) {
        const obgAccountClient = {
          [eventData.body?.userId]: {
            ...eventData.body,
          },
        }
        dispatch(setAccountClient(obgAccountClient))
      }
      // @ts-ignore
      if (userMeRef?.current?.client?.id === eventData.body?.userId) {
        dispatch(setAccountController(eventData.body))
      }
      break
    }

    case 'RELATIONSHIP_DELETED':
    case 'RELATIONSHIP_CREATED':
    case 'RELATIONSHIP_UPDATED': {
      const payload = {
        operation: eventData.subType,
        relationship: eventData.body,
      }
      dispatch(setClientsToChangeRelationship(payload))
      dispatch(setFriendsBoolean())
      break
    }

    case 'CHAT': {
      handleChatEvent(eventData, {
        dispatch,
        userMeRef,
        roleRef,
        messagesSelectedIdRef,
        getClickRef,
        navigate,
        playSound, // Передача playSound в handleChatEvent
        stopAllSounds,
      })
      break
    }
    case 'ROOM_CREATED': {
      handleRoomsEvent(eventData, {
        dispatch,
        stopAllSounds,
      })
      break
    }
    case 'CALL_CREATED': {
      if (eventData.subType === 'CALL_CREATED') {
        if (eventData.body.type === 'CALL_FROM_CLIENT' || eventData.body.type === 'CALL_FROM_MODEL' || eventData.body.type === 'CALL_FROM_STAFF') {
          navigate(`/room/${eventData.body.id}`)
          playSound('calls')
          debugger
        }
      }
      break
    }
    default:
      // Обработка других подтипов при необходимости
      break
  }
}

interface HandleChatEventParams {
  dispatch: Dispatch<any>
  navigate: any
  userMeRef: React.MutableRefObject<any>
  roleRef: React.MutableRefObject<IUserRole>
  messagesSelectedIdRef: React.MutableRefObject<string | null>
  getClickRef: React.MutableRefObject<boolean>
  playSound: (eventType: EEventType) => Promise<void>
  stopAllSounds: () => void
}

const handleChatEvent = (
  eventData: any,
  { dispatch, navigate, userMeRef, roleRef, messagesSelectedIdRef, getClickRef, playSound, stopAllSounds }: HandleChatEventParams,
) => {
  if (eventData.body.type === 'MESSAGE_CREATED') {
    const payload = {
      ...eventData,
      role: roleRef.current,
    }
    if (!payload?.body.privateChat?.approvedByModel && !payload?.body.privateChat?.approvedByClient && !payload?.body.privateChat?.approvedByStaff) {
      return null
    } else {
      dispatch(addMessagePrivetChat(payload))
    }

    if (eventData.body.message?.type === 'GENERAL') {
      // Определение, отправлено ли сообщение самим пользователем или получено
      const isSentByMe = userMeRef.current?.userData?.user?.id === eventData.body.message?.senderId
      const soundType: EEventType = isSentByMe ? 'sendMessage' : 'receiveMessage'
      playSound(soundType)
    }
  }

  if (eventData.body.type === 'MESSAGE_READ') {
    const payload = {
      ...eventData,
      role: roleRef.current,
      // @ts-ignore
      userMeId: userMeRef.current?.userData?.user?.id,
    }
    dispatch(markMessageAsRead(payload))
  }

  if (eventData.body.type === 'PRIVATE_CHAT_APPROVED_BY_CLIENT') {
    dispatch(setMessagePrivetChat(eventData.body))
  }

  if (eventData.body.type === 'PRIVATE_CHAT_APPROVED_BY_MODEL') {
    const { approvedByModel } = eventData.body.privateChat
    if (!approvedByModel && roleRef.current === 'CLIENT') {
      dispatch(setMessagePrivetChat(eventData.body))
    } else {
      dispatch(setMessagePrivetChat(eventData.body))
    }
  }

  if (eventData.body.type === 'PRIVATE_CHAT_APPROVED_BY_STAFF') {
    dispatch(setMessagePrivetChat(eventData.body))
    dispatch(changeFullPrivateChats())
  }
  if (
    eventData.body.type === 'PRIVATE_CHAT_DISAPPROVED_BY_CLIENT' ||
    eventData.body.type === 'PRIVATE_CHAT_DISAPPROVED_BY_MODEL' ||
    eventData.body.type === 'PRIVATE_CHAT_DISAPPROVED_BY_STAFF'
  ) {
    if (roleRef.current === 'MODEL') {
      dispatch(deleteClient(eventData?.body?.privateChat?.clientId))
    }
    if (roleRef.current === 'CLIENT') {
      dispatch(deleteModel(eventData?.body?.privateChat?.modelId))
    }
    // сделать возмодно и для стафа чтобы reletiship парвильно отрабтаывала
    dispatch(deleteMessagePrivetChats(eventData?.body?.privateChatId))
    dispatch(setMessagesSelectedUser(null))
    dispatch(setMessagesSelectedId(null))
  }

  if (eventData.body.message?.type === 'SYSTEM_PRIVATE_CHAT_DISABLED') {
    dispatch(deleteMessagePrivetChats(eventData?.body?.privateChatId))
    if (messagesSelectedIdRef.current === eventData?.body?.privateChatId) {
      dispatch(setMessagesSelectedUser(null))
      dispatch(setMessagesSelectedId(null))
    }
  }

  if (eventData.body.type === 'PRIVATE_CHAT_STAFF_ASSIGNED') {
    const payload: StaffViewDto = {
      staff: {
        id: eventData.body.privateChat?.staffId,
        // @ts-ignore
        name: 'Admin',
      },
      user: {
        id: eventData.body.privateChat?.staffId,
        role: 'STAFF',
        isDeleted: false,
        isDisabled: false,
      },
    }
    dispatch(setMessagesSelectedUser(payload))
    dispatch(setMessagesSelectedId(eventData?.body?.privateChat?.id || null))
  }
}

interface HandleCallEventParams {
  dispatch: Dispatch<any>
  roleRef: React.MutableRefObject<IUserRole>
  navigate: any
  handleCreateRoom: (userId: string) => void
  playSound: (eventType: EEventType) => Promise<void>
  stopAllSounds: () => void
}

const handleCallEvent = (eventData: any, { dispatch, roleRef, navigate, handleCreateRoom, playSound, stopAllSounds }: HandleCallEventParams) => {
  if (eventData.body.callEventType === 'CALL_STARTED') {
    dispatch(popUpOpen('calls'))
    dispatch(setPopUpData({ data: eventData }))
    playSound('calls')
  }

  if (eventData.body.callEventType === 'CALL_ACCEPTED') {
    stopAllSounds()
    dispatch(popUpInitState())
    if (roleRef.current === 'MODEL' || roleRef.current === 'STAFF') {
      handleCreateRoom(eventData.body.callerUserId)
    }
  }
}
// create rooms
interface HandleRoomsEventParams {
  dispatch: Dispatch<any>
  stopAllSounds: () => void
}

const handleRoomsEvent = (eventData: any, { dispatch, stopAllSounds }: HandleRoomsEventParams) => {
  if (eventData.subType === 'ROOM_CREATED') {
    debugger
    stopAllSounds()
    dispatch(setRoom(eventData))
  }
}
