import Select from 'react-dropdown-select'
import { FormikHandlers, FormikValues } from 'formik'
import { optionsLanguage as originalOptionsLanguage } from 'UI/SelectCustom/constValue'
import { SvgChevronDown } from 'images/svg'

type LanguageSelectProps = {
  name: string // имя поля для Formik
  placeholder: string // placeholder для поля
  formik: {
    setFieldValue: (field: string, value: any) => void
    values: FormikValues
    handleChange: FormikHandlers['handleChange']
    handleBlur: FormikHandlers['handleBlur']
    touched: FormikValues
    errors: FormikValues
  }
}

// Фильтруем дубликаты по backendName:
const uniqueOptionsLanguage = originalOptionsLanguage.reduce(
  (acc, current) => {
    if (!acc.find(item => item.backendName === current.backendName)) {
      acc.push(current)
    }
    return acc
  },
  [] as typeof originalOptionsLanguage,
)

const LanguagesSelect = ({ name, formik, placeholder }: LanguageSelectProps) => {
  const handleSelected = (selectedOptions: any[]) => {
    const values = selectedOptions.map(option => option.value)
    formik.setFieldValue(name, values)
  }

  const selectedValues =
    formik.values[name]?.map((e: any) => {
      const matchedOption = uniqueOptionsLanguage.find(option => option?.backendName === e)
      return {
        label: matchedOption ? matchedOption.name : e,
        value: matchedOption ? matchedOption.backendName : e,
      }
    }) || []

  const isError = Boolean(formik.touched[name] && formik.errors[name])

  return (
    <div className='select-item'>
      <Select
        dropdownHandleRenderer={({ state }) => (
          <button type='button' className='dropdown-select-button'>
            <SvgChevronDown className={`dropdown-select-icon-${state.dropdown ? 'up' : 'down'}`} />
          </button>
        )}
        options={uniqueOptionsLanguage}
        values={selectedValues}
        onChange={handleSelected}
        placeholder={placeholder}
        multi={true}
        searchable={false}
      />
      {isError && <div className='error-message'>{formik.errors[name]}</div>}
    </div>
  )
}

export default LanguagesSelect
