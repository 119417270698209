import React, { useEffect } from 'react'
import './scss/index.scss'
import { useGiftGetAllQuery, useGiftSendMutation } from 'store/apiMain/mainApi'
import RenderGiftIconAndName from 'utils/RenderGiftIconAndName/RenderGiftIconAndName'
import { useDispatch, useSelector } from 'react-redux'
import { popUpOpen, setPopUpData } from 'store/reducer/popUp/reducer'
import { toastSuccess } from '../../../elements/Notification/Notification'
import { selectRole } from 'store/reducer/token/selector'
import useSoundPlayer from '../../../hooks/useSoundPlayer'

interface IProps {
  id: string | undefined
  isPopUp?: boolean
  status?: string
}

const TooltipGift: React.FC<IProps> = ({ id, isPopUp = false, status }) => {
  const { data: giftsData, isLoading, error } = useGiftGetAllQuery()
  const dispatch = useDispatch()
  const [addGift, { isSuccess }] = useGiftSendMutation()
  const { playSound, stopAllSounds } = useSoundPlayer(false)

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Gift sent successfully')
      playSound('gift')
    }
  }, [isSuccess])

  const handleSendGift = (giftId: number) => {
    if (giftId && id) {
      const payloadGift = {
        status,
        modelId: id,
        price: giftsData?.find(e => e.id === giftId)?.price || 0,
        giftSendRequest: {
          giftId: giftId,
        },
      }

      if (isPopUp) {
        dispatch(popUpOpen('presentPopUp'))
        dispatch(setPopUpData({ data: payloadGift }))
      } else {
        addGift(payloadGift)
      }
    }
    // Assuming addGift sends the gift using an API mutation
  }

  return (
    <div className='TooltipGift'>
      <div className='tooltips-gift'>
        <div className='tooltips-gift_block'>
          {giftsData &&
            giftsData.map((gift, index) => (
              <div className={`tooltips-gift_block__box `} key={gift.id} onClick={() => handleSendGift(Number(gift.id))}>
                <div>
                  <RenderGiftIconAndName index={index + 1} />
                </div>
                <button type='button'>{gift.price} coin</button>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default TooltipGift
