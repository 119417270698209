import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSocket, setWebSocket } from 'store/reducer/socket/reducer'
import { getSocket, getSocketIsConnected, getSocketToken } from 'store/reducer/socket/selector'

import { selectRole, selectTokenData, selectUserMe } from 'store/reducer/token/selector'
import { getSelectorMessagesSelectedId } from 'store/reducer/messages/selectors'
import { selectorGetClick } from 'store/reducer/global/selectors'
import { useNavigate } from 'react-router-dom'
import { useCreateRoom } from '../Components/Global/VideoChat/hook/useCreateRoom'
import { ActivityStatusType } from './types'
import { handleEventData } from './eventHandlers'

import {
  sendActivityUpdate as sendActivityUpdateAction,
  sendSubscribe as sendSubscribeAction,
  sendChatTyping as sendChatTypingAction,
  startCall as startCallAction,
  handleCall as handleCallAction,
  handleLogoutSocket as handleLogoutSocketAction,
} from './websocketActions'
import useSoundPlayer from '../hooks/useSoundPlayer'
import { getAccount, getAccountClients } from 'store/reducer/accountController/selectors'
import { setSocketTestData } from 'store/reducer/socketTest/reducer'

const baseWsUrl: string = process.env.REACT_APP_HOST ? `ws://${process.env.REACT_APP_HOST}` : 'ws://127.0.0.1:5000/'

const useWebSocket = () => {
  const dispatch = useDispatch()
  const isConnected = useSelector(getSocketIsConnected)
  const role = useSelector(selectRole)
  const account = useSelector(getAccount)
  const accountRef = useRef(account)
  const { handleCreateRoom } = useCreateRoom()
  const socket = useSelector(getSocket)
  const socketToken = useSelector(getSocketToken)
  const userMeSelected = useSelector(selectUserMe)
  const messagesSelectedId = useSelector(getSelectorMessagesSelectedId)
  const messagesSelectedIdRef = useRef(messagesSelectedId)
  const userMeRef = useRef(userMeSelected)
  const roleRef = useRef(role)
  const getClick = useSelector(selectorGetClick)

  const getClickRef = useRef(getClick)
  const { playSound, stopAllSounds } = useSoundPlayer(getClickRef.current)
  const navigate = useNavigate()

  useEffect(() => {
    messagesSelectedIdRef.current = messagesSelectedId
  }, [messagesSelectedId])

  useEffect(() => {
    userMeRef.current = userMeSelected
  }, [userMeSelected])
  useEffect(() => {
    accountRef.current = account
  }, [account])

  useEffect(() => {
    roleRef.current = role
  }, [role])

  useEffect(() => {
    getClickRef.current = getClick
  }, [getClick])
  const connectWebSocket = useCallback(() => {
    if (socketToken && !isConnected) {
      const newSocket = new WebSocket(`${baseWsUrl}/ws?ticket=${socketToken}`)
      dispatch(setWebSocket(newSocket))
      dispatch(setSocket(true))

      newSocket.onmessage = event => {
        const eventData = JSON.parse(event.data)
        dispatch(setSocketTestData(eventData))
        handleEventData(eventData, {
          dispatch,
          userMeRef,
          roleRef,
          messagesSelectedIdRef,
          getClickRef,
          navigate,
          handleCreateRoom,
          playSound,
          stopAllSounds,
          accountRef,
        })
      }

      return () => {
        newSocket.close()
        dispatch(setWebSocket(null))
        dispatch(setSocket(false))
      }
    }
  }, [isConnected, dispatch, socketToken, navigate, handleCreateRoom])
  // Wrap the imported functions to include the current socket and dispatch
  const sendActivityUpdate = (status: ActivityStatusType) => {
    sendActivityUpdateAction(socket, status)
  }

  const sendSubscribe = (subscribeToUserIds: string[]) => {
    sendSubscribeAction(socket, subscribeToUserIds)
  }

  const sendChatTyping = (params: { privateChatId: string; targetUserId: string }) => {
    sendChatTypingAction(socket, params)
  }

  const startCall = (targetUserId: string) => {
    startCallAction(socket, targetUserId)
  }

  const handleCall = (callerUserId: string, actionType: 'CALL_START' | 'CALL_CANCEL' | 'CALL_ACCEPT') => {
    handleCallAction(socket, callerUserId, actionType)
  }

  const handleLogoutSocket = () => {
    handleLogoutSocketAction(socket, dispatch)
  }

  return {
    connectWebSocket,
    sendSubscribe,
    sendActivityUpdate,
    handleLogoutSocket,
    sendChatTyping,
    startCall,
    handleCall,
  }
}

export default useWebSocket
