import Item from 'Components/User/Girl/Item'
import React, { useEffect } from 'react'
import { TokenRevokeApiArg, useUserGetFullMeQuery } from 'store/apiMain/mainApi'
import './scss/index.scss'
import { useNavigate } from 'react-router-dom'
import { toastError, toastInfo, toastSuccess } from '../../../../elements/Notification/Notification'
import Delete from '../../../Global/Delete/Delete'
import { resetStateToken } from '../../../../store/reducer/token/reducer'
import { useDispatch, useSelector } from 'react-redux'
import useWebSocket from '../../../../socket/useWebSocket'
import { selectRole, selectTokenData } from '../../../../store/reducer/token/selector'
import { emptySplitApi, useTokenRevokeMutation } from 'store/apiMain/emptyApi'
import { useCreateRoom } from '../../../Global/VideoChat/hook/useCreateRoom'
import { selectVideoChatObg } from 'store/reducer/videoChat/selector'
import { t } from 'i18next'
import useLogout from '../../../../hooks/useLogout'
import { getSelectorSetMessagePrivetChats } from 'store/reducer/messages/selectors'

const MainInfo = () => {
  const { sendActivityUpdate } = useWebSocket()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = useSelector(selectTokenData)
  const { data, isLoading, isError } = useUserGetFullMeQuery()
  const dataPrivateChats = useSelector(getSelectorSetMessagePrivetChats)
  console.log(dataPrivateChats)
  const logOutHook = useLogout()
  console.log()
  const addFriends = () => {
    toastSuccess('Себя нельзя добоавлять в друзья!')
  }
  const addFavorites = () => {
    toastSuccess('Себя нельзя добоавлять в избранное!')
  }
  const addDelete = () => {
    toastSuccess('Себя нельзя удалять из друзей!')
  }
  const { handleCreateRoom, isLoading: isLoadingRoomCreate, isError: isErrorRoomCreate, isSuccess: isSuccessRoomCreate, error } = useCreateRoom()
  const videoChatToken = useSelector(selectVideoChatObg)?.room?.id
  const role = useSelector(selectRole)
  useEffect(() => {
    if (videoChatToken && role === 'MODEL') {
      // @ts-ignore
      navigate(`/model/profile/main-info/room/${videoChatToken}`)
    }
  }, [videoChatToken])
  return (
    <>
      <div className='MainInfo'>
        <div className='MainInfo-block'>
          <button
            onClick={() => {
              handleCreateRoom()
            }}
            className='rooms'
          >
            {!isLoadingRoomCreate ? 'Настроить комнату' : 'Loading'}
          </button>
        </div>
      </div>
      {data?.model !== undefined ? (
        <Item data={data} addFriends={addFriends} addFavorites={addFavorites} addDelete={addDelete} role={'model'} />
      ) : null}
      <div className='MainInfo'>
        <div style={{ marginBottom: '100px' }} className='MainInfo-block'>
          <button
            onClick={() => {
              navigate('/model/profile/main-info/edit')
            }}
          >
            Редактировать
          </button>
          <button onClick={logOutHook} type='submit'>
            {t('logout')}
          </button>
        </div>
      </div>
    </>
  )
}

export default MainInfo
