import React, { useState } from 'react'
import Tippy from '@tippyjs/react'
import { useSelector } from 'react-redux'
import { selectRole } from 'store/reducer/token/selector'
import { getSelectorMessagesSelectedUser, getSelectorSetMessagePrivetChats } from 'store/reducer/messages/selectors'
import { selectVideoChatObg, selectVideoChatParticipants } from 'store/reducer/videoChat/selector'
import TooltipGift from 'UI/Tooltip/GiftTooltip/GiftTooltip'
import { SvgBlackGift1 } from '../../../../../../../../images/svg'
import { use } from 'i18next'

const Gift = () => {
  const role = useSelector(selectRole)
  const participants = useSelector(selectVideoChatParticipants)
  // @ts-ignore
  const modelId = useSelector(selectVideoChatObg)
  console.log(modelId)
  console.log(participants)
  return (
    <>
      {role === 'CLIENT' && (
        <button className='lk-button lk-chat-toggle'>
          <Tippy className='gift' interactive trigger='click' content={<TooltipGift id={'1'} />} placement='bottom'>
            <div style={{ cursor: 'pointer' }} id='dots-vertical' className='messages-dialog_block__left___menu'>
              <SvgBlackGift1 />
            </div>
          </Tippy>
        </button>
      )}
    </>
  )
}

export default Gift
