import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import Photo from 'UI/Photo/Photo'
import { FormControl } from 'layouts-elements/FormControl/FormControl'
import { useModelRequestCreateMutation, useFileCreateMutation, useUserGetFullMeQuery, useModelRequestGetMeQuery } from 'store/apiMain/mainApi'
import Audio from 'UI/Audio/Audio'
import {
  optionsBirthYear,
  optionsWeight,
  optionsHeight,
  optionsChest,
  optionsWaist,
  optionsBreastSize,
  optionsHairColor,
  optionsSexualOrientation,
  optionsBodyType,
  optionsHairLength,
  optionsHips,
} from 'UI/SelectCustom/constValue'
import { toastSuccess } from 'elements/Notification/Notification'
import { useGetFileMutation } from 'store/apiMain/emptyApi'
import { useNavigate } from 'react-router-dom'
import Video from 'UI/Video/Video'
import { useDispatch, useSelector } from 'react-redux'
import { selectSteps1 } from 'store/reducer/steps/selector'
import { setSteps1 } from 'store/reducer/steps/reducer'
import { getSocketStepApprovedModel } from '../../../../../store/reducer/socket/selector'
import { setRequestModelError } from 'store/reducer/global/reducer'
import { useTranslation } from 'react-i18next'
import CropeMain from '../../../../Global/Crope/CropeMain'
import CustomTextArea from '../../../../../UI/CustomTextArea/CustomTextArea'
import { countChars, RawContent } from '../../../Steps/Step1/Step1'
import SelectItem from 'Components/Model/Steps/Step1/SelectItem'
import LanguagesSelect from 'Components/Model/Steps/LanguagesSelect'

const EditRequest: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { data: modelData, isLoading, isError } = useUserGetFullMeQuery()
  const { data: modelRequest, isLoading: byIdLoading, isError: byIdError, refetch } = useModelRequestGetMeQuery()
  const [checkedProverka, setCheckedProverka] = useState(false)
  const steps1 = useSelector(selectSteps1)

  const socketNoStepApprovedModel = useSelector(getSocketStepApprovedModel)
  if (byIdError) {
    dispatch(setRequestModelError(true))
    navigate('/model/profile/main-info')
  }
  useEffect(() => {
    refetch()
  }, [socketNoStepApprovedModel])
  useEffect(() => {
    if (modelRequest?.request?.state === undefined) {
      navigate('/model/profile/main-info')
    }
  }, [modelRequest])

  useEffect(() => {
    if (modelRequest?.request?.state === 'CREATE_NEW' && steps1 === false) {
      navigate('/model/registration/step/2')
      dispatch(setSteps1(true))
    }
  }, [modelRequest])

  useEffect(() => {
    if (modelRequest !== null && modelRequest !== undefined) {
      setCheckedProverka(true)
      const profilePhoto = modelRequest?.mainInfo?.profilePhoto || modelData?.profilePhoto
      const profilePhotoID = profilePhoto?.file?.id
      // @ts-ignore
      setProfilePhotoIds(profilePhotoID)

      const arrayPhoto = modelRequest?.mainInfo?.photos || modelData?.photos
      const idPhotos = arrayPhoto?.map(item => item?.file?.id)
      // @ts-ignore
      setPhotoIds(idPhotos)
      //

      const arrayVideos = modelRequest?.mainInfo?.videos || modelData?.videos
      const idVideo = arrayVideos?.map(item => item?.file?.id)
      // @ts-ignore
      setVideosIds(idVideo)
      //
      const arrayAudios = modelRequest?.mainInfo?.audios || modelData?.audios
      const idAudio = arrayAudios?.map(item => item?.file?.id)
      // @ts-ignore
      setAudiosIds(idAudio)
      formik.resetForm({
        values: {
          languages: modelRequest?.mainInfo?.languages || modelData?.languages || [],
          dataType: (modelRequest?.mainInfo?.dataType as string) || 'MAIN_INFO',
          // @ts-ignore
          name: modelRequest?.mainInfo?.name || modelData?.model?.name,
          // @ts-ignore
          selfInformation:
            // Проверка на наличие строки перед парсингом JSON
            modelRequest?.mainInfo?.selfInformation
              ? JSON.parse(modelRequest?.mainInfo?.selfInformation)
              : modelData?.model?.selfInformation
                ? JSON.parse(modelData?.model?.selfInformation)
                : '',
          // @ts-ignore
          birthYear: modelRequest?.mainInfo?.birthYear || modelData?.model?.birthYear,
          // @ts-ignore
          weight: modelRequest?.mainInfo?.weight || modelData?.model?.weight,
          // @ts-ignore
          height: modelRequest?.mainInfo?.height || modelData?.model?.height,
          // @ts-ignore
          chest: modelRequest?.mainInfo?.chest || modelData?.model?.chest,
          // @ts-ignore
          waist: modelRequest?.mainInfo?.waist || modelData?.model?.waist,
          // @ts-ignore
          hips: modelRequest?.mainInfo?.hips || modelData?.model?.hips,
          // @ts-ignore
          hairColor: modelRequest?.mainInfo?.hairColor || modelData?.model?.hairColor,
          // @ts-ignore
          breastSize: modelRequest?.mainInfo?.breastSize || modelData?.model?.breastSize,
          // @ts-ignore
          sexualOrientation: modelRequest?.mainInfo?.sexualOrientation || modelData?.model?.sexualOrientation,
          // @ts-ignore
          bodyType: modelRequest?.mainInfo?.bodyType || modelData?.model?.bodyType,
          // @ts-ignore
          hairLength: modelRequest?.mainInfo?.hairLength || modelData?.model?.hairLength,
          // @ts-ignore
          profilePhoto: modelRequest?.mainInfo?.profilePhoto || modelData?.profilePhoto,
          // @ts-ignore
          photos: modelRequest?.mainInfo?.photos || modelData?.photos,
          // @ts-ignore
          videos: modelRequest?.mainInfo?.videos || modelData?.videos,
          // @ts-ignore
          audios: modelRequest?.mainInfo?.audios || modelData?.audios,
          // @ts-ignore
          // cameraAvailable: modelRequest?.mainInfo?.isCameraAvailable,
          // // @ts-ignore
          // microphoneAvailable: modelRequest?.mainInfo?.isMicrophoneAvailable,
          // // @ts-ignore
          // virtualSexAvailable: modelRequest?.mainInfo?.isVirtualSexAvailable,
          // @ts-ignore
        },
      })
    }
  }, [modelRequest])

  const [getFile] = useGetFileMutation()
  const [addFile] = useFileCreateMutation()
  const [addRequest, { isSuccess }] = useModelRequestCreateMutation()

  const [profilePhoto, setProfilePhoto] = useState<string | null>(null)
  const [profilePhotoIds, setProfilePhotoIds] = useState<string | null>(null)

  const [photos, setPhotos] = useState<any[]>([])
  const [photoIds, setPhotoIds] = useState<string[]>([])

  const [videos, setVideos] = useState<any[]>([])
  const [videosIds, setVideosIds] = useState<string[]>([])

  const [audios, setAudios] = useState<any[]>([])
  const [audiosIds, setAudiosIds] = useState<string[]>([])

  const initialValues = {
    languages: ['ru'],
    dataType: 'MAIN_INFO',
    name: null,
    selfInformation: null,
    birthYear: null,
    weight: null,
    height: null,
    chest: null,
    waist: null,
    hips: null,
    hairColor: null,
    breastSize: null,
    sexualOrientation: null,
    bodyType: null,
    hairLength: null,
    profilePhoto: null,
    photos: [],
    videos: [],
    audios: [],
  }

  const validationSchema = yup.object().shape({
    dataType: yup.string().required('Data type is required'),
    name: yup
      .string()
      .required(t('stepOne.nameRequired'))
      .min(2, t('stepOne.nameMin'))
      .max(50, t('stepOne.nameMax'))
      .matches(/^[\p{L}-]+$/u, t('stepOne.nameInvalid')),
    selfInformation: yup
      .mixed<RawContent>()
      .test(
        'selfInformationRequired',
        t('stepOne.selfInformationRequired'),
        value => !!value && countChars(value as RawContent) > 0, // Проверка на наличие текста
      )
      .test(
        'selfInformationMin',
        t('stepOne.selfInformationMin'),
        value => !!value && countChars(value as RawContent) >= 10, // Минимум 10 символов
      )
      .test(
        'selfInformationMax',
        t('stepOne.selfInformationMax'),
        value => !!value && countChars(value as RawContent) <= 10000, // Максимум 10000 символов
      ),
    birthYear: yup.number().required('Birth year is required').min(1900).max(new Date().getFullYear()),
    weight: yup.number().required('Weight is required'),
    height: yup.number().required('Height is required'),
    chest: yup.number().required('Chest is required'),
    waist: yup.number().required('Waist is required'),
    hips: yup.number().required('Hips is required'),
    hairColor: yup.string().required('Hair color is required'),
    breastSize: yup.number().required('Breast size is required'),
    sexualOrientation: yup.string().required('Sexual orientation is required'),
    bodyType: yup.string().required('Body type is required'),
    hairLength: yup.string().required('Hair length is required'),
    profilePhoto: yup.mixed().required(t('stepOne.mainPhotoRequired')).notOneOf([null], t('stepOne.mainPhotoRequired')),
    photos: yup.array().required('At least one photo is required'),
    videos: yup.array().required('At least one video is required'),
    audios: yup.array().required('At least one audio is required'),
  })

  const onSubmit = (values: any) => {
    const body = {
      ...values,
      selfInformation: JSON.stringify(values.selfInformation),
      profilePhoto: profilePhotoIds,
      photos: [...photoIds],
      videos: [...videosIds],
      audios: [...audiosIds],
    }
    addRequest({ body })
  }
  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Данные отправлены')
      refetch()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [isSuccess])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })
  const proverkaInfo = () => {
    if (checkedProverka && modelRequest?.request?.state !== 'CREATE_NEW' && modelRequest?.mainInfo) {
      return (
        <div className='checkSteps'>
          <div className='checkSteps-block'>
            <p>{t('stepOne.infoPending')}</p>
          </div>
        </div>
      )
    }
    if (modelRequest?.request?.state === 'CREATE_NEW') {
      return (
        <div className='checkSteps'>
          <div className='checkSteps-block'>
            <p>{t('spetOne.infoApproved')}</p>
          </div>
        </div>
      )
    }
    return null
  }
  const [clickSubmit, setClickSubmit] = useState(false)

  return (
    <div className='step1-information edit'>
      <div className='edit__title'>
        <h2>Редактирование информации</h2>
      </div>
      {proverkaInfo()}
      <form onSubmit={formik.handleSubmit}>
        <label className='name'>
          <span>{t('Name')}</span>
          <FormControl name='name' type='text' formik={formik} placeholder='' />
        </label>
        <div className='step1-information-block'>
          <div className='step1-information-block__item'>
            <label>Birth year</label>
            <div className='step1-information-block__item_box'>
              <SelectItem name='birthYear' options={optionsBirthYear} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Weight</label>
            <div className='step1-information-block__item_box'>
              <SelectItem name='weight' options={optionsWeight} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Height</label>
            <div className='step1-information-block__item_box'>
              <SelectItem name='height' options={optionsHeight} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Chest</label>
            <div className='step1-information-block__item_box'>
              <SelectItem name='chest' options={optionsChest} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Waist</label>
            <div className='step1-information-block__item_box'>
              <SelectItem name='waist' options={optionsWaist} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Hips</label>
            <div className='step1-information-block__item_box'>
              <SelectItem name='hips' options={optionsHips} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Breast size</label>
            <div className='step1-information-block__item_box'>
              <SelectItem name='breastSize' options={optionsBreastSize} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Hair color</label>
            <div className='step1-information-block__item_box'>
              <SelectItem name='hairColor' options={optionsHairColor} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Sexual orientation</label>
            <div className='step1-information-block__item_box'>
              <SelectItem name='sexualOrientation' options={optionsSexualOrientation} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Body type</label>
            <div className='step1-information-block__item_box'>
              <SelectItem name='bodyType' options={optionsBodyType} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Hair length</label>
            <div className='step1-information-block__item_box'>
              <SelectItem name='hairLength' options={optionsHairLength} formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Spoken languages</label>
            <div className='step1-information-block__item_box'>
              <LanguagesSelect name='languages' formik={formik} placeholder={t('Select...')} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Main photo</label>
            <div className='step1-photo profile-photo'>
              <CropeMain
                formik={formik}
                name='profilePhoto'
                nameText={formik.values.name}
                profilePhoto={profilePhoto}
                setProfilePhoto={setProfilePhoto}
                addFile={addFile}
                getFile={getFile}
                setProfilePhotoIds={setProfilePhotoIds}
                profilePhotoIds={profilePhotoIds}
              />
            </div>
          </div>
          <div className='step1-information-block__item photos'>
            <label>Additional photos</label>
            <div className='step1-information-block__item_box step1-photo'>
              <Photo photoIds={photoIds} photos={photos} setPhotos={setPhotos} addFile={addFile} getFile={getFile} setPhotoIds={setPhotoIds} />
            </div>
            {formik.errors.photos && formik.touched.photos && (
              <div className='errorPhoto'>
                <div className='errorPhoto-block'>
                  <p>{formik.errors.photos}</p>
                </div>
              </div>
            )}
          </div>
          <div className='step1-information-block__item'>
            <label>Video files</label>
            <div className='step1-information-block__item_box step1-video'>
              <Video videoIds={videosIds} videos={videos} setVideos={setVideos} addFile={addFile} getFile={getFile} setVideoIds={setVideosIds} />
            </div>
          </div>
          <div className='step1-information-block__item'>
            <label>Voice</label>
            <div className='step1-information-block__item_box step1-audio'>
              <Audio audioIds={audiosIds} audios={audios} setAudios={setAudios} addFile={addFile} getFile={getFile} setAudioIds={setAudiosIds} />
            </div>
          </div>
          {/*<div className="step1-information-block_box boxCheck">*/}
          {/*    <div className="customBlock">*/}
          {/*        <div className="step1-information-block_box__item">*/}
          {/*            <p>Camera</p>*/}
          {/*            <FormControl name="cameraAvailable" type="checkbox" formik={formik}/>*/}
          {/*        </div>*/}
          {/*        <div className="step1-information-block_box__item">*/}
          {/*            <p>Microphone</p>*/}
          {/*            <FormControl name="microphoneAvailable" type="checkbox" formik={formik}/>*/}
          {/*        </div>*/}
          {/*        <div className="step1-information-block_box__item">*/}
          {/*            <p>Virtual sex</p>*/}
          {/*            <FormControl name="virtualSexAvailable" type="checkbox" formik={formik}/>*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</div>*/}
          <div className='step1-information-block__item'>
            <label>Personal info</label>
            <div className='step1-information-block__item_box step1-textarea'>
              <CustomTextArea
                formik={formik}
                initialRawContent={formik.values.selfInformation}
                onChangeRaw={rawContent => formik.setFieldValue('selfInformation', rawContent)}
              />
            </div>
          </div>
          <div className='step1-information-block__button'>
            <button
              onClick={() => {
                setClickSubmit(true)
              }}
              type='submit'
            >
              {t('save')}
            </button>
          </div>
        </div>
      </form>
      {/*<div>*/}
      {/*    Status:{' '}*/}
      {/*    {byIdData?.request?.state === 'CREATE_SECOND_STAGE'*/}
      {/*        ? 'Admin approved your data'*/}
      {/*        : byIdData?.request?.state}*/}
      {/*</div>*/}
      {/*<div>*/}
      {/*    {byIdData?.request?.state === 'CREATE_SECOND_STAGE' ? <Documents /> : byIdData?.request?.state}*/}
      {/*</div>*/}
    </div>
  )
}

export default EditRequest
