/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CursorPageMessageResponseMe,
  CursorPagePrivateChatViewResponseMe,
  IChatFilter,
  IMessagesStore,
  ISendMessages,
  PrivateChatResponseMe,
  PrivateChatViewResponseMe,
} from './types'
import {
  ClientViewDto,
  CursorPageMessageResponse,
  CursorPagePrivateChatViewResponse,
  MessageResponse,
  ModelViewDto,
  StaffViewDto,
} from '../../apiMain/mainApi'
import { boolean } from 'yup'
import { IChatTyping, IWebSocketMessageChat } from '../../../socket/types'

// ==========================================:
export const initialState: IMessagesStore = {
  messagesSelectedStaff: null,

  messagesSelectedId: null,
  messagesSelectedUser: null,

  messagePrivetChats: null,

  fetchFrom: null,

  clientPhoto: null,
  modelPhoto: null,

  adminSelectedChatType: {
    approvedByClient: undefined,
    approvedByModel: undefined,
    approvedByStaff: undefined,
    clientId: undefined,
    modelId: undefined,
    staffId: undefined,
    byRole: undefined,
    active: undefined,
  },
  sendMessages: {
    recipientId: '',
    senderId: '',
  },
  // нужна для админа, чтобы коректно работало смена чатов по фильтрам
  // также используеться для того чтобы начать чат с админом и оновить приватный чат
  // getAllPrivateChatsSuccess для блакировки в дамине на бесконеый апросы
  changeFullPrivateChats: false,
  allPrivateChatsSuccess: false,

  clientsFetchAndBlock: false,
  modelsFetchAndBlock: false,
}

const sortChatsByLastMessage = (chats: PrivateChatViewResponseMe[]) => {
  return chats.sort((a, b) => {
    const dateA = a.lastMessage ? new Date(a.lastMessage.createdAt).getTime() : 0
    const dateB = b.lastMessage ? new Date(b.lastMessage.createdAt).getTime() : 0
    return dateB - dateA
  })
}
// ==========================================:
const messagesSlice = createSlice({
  name: '@@messages',
  initialState,
  reducers: {
    // читать выше
    changeFullPrivateChats: state => {
      state.changeFullPrivateChats = !state.changeFullPrivateChats
    },
    // читать выше
    getAllPrivateChatsSuccess: state => {
      state.allPrivateChatsSuccess = true
    },
    changeClientsFetchAndBlock: state => {
      state.clientsFetchAndBlock = true
    },
    changeModelsFetchAndBlock: state => {
      state.modelsFetchAndBlock = true
    },
    sendMessages: (state, action: PayloadAction<ISendMessages>) => {
      state.sendMessages = {
        recipientId: action.payload.recipientId,
        senderId: action.payload.senderId,
      }
    },
    setAdminSelectedChatType: (state, action: PayloadAction<Partial<IChatFilter>>) => {
      state.adminSelectedChatType = {
        ...state.adminSelectedChatType,
        ...action.payload,
      }
    },
    setMessagesSelectedStaffTrue: state => {
      state.messagesSelectedStaff = true
    },
    setMessagesSelectedStaffFalse: state => {
      state.messagesSelectedStaff = false
    },
    setMessagesSelectedId: (state, action: PayloadAction<string | null>) => {
      state.messagesSelectedId = action.payload
    },
    setMessagesSelectedUser: (state, action: PayloadAction<ClientViewDto | ModelViewDto | StaffViewDto | null>) => {
      state.messagesSelectedUser = action.payload
    },
    setPrivetChats: (state, action: PayloadAction<CursorPageMessageResponse>) => {},
    setMessagePrivetChats: (state, action: PayloadAction<CursorPagePrivateChatViewResponseMe>) => {
      if (!state.messagePrivetChats) {
        const sortedContent = action.payload.content.slice().sort((a, b) => {
          const aLastMessageDate = a.lastMessage ? new Date(a.lastMessage.createdAt).getTime() : 0
          const bLastMessageDate = b.lastMessage ? new Date(b.lastMessage.createdAt).getTime() : 0
          return bLastMessageDate - aLastMessageDate
        })

        state.messagePrivetChats = {
          ...action.payload,
          content: sortedContent,
        }
      } else {
        const updatedContent = action.payload.content.map(newChat => {
          const existingChat = state?.messagePrivetChats?.content.find(e => e.privateChat.id === newChat.privateChat.id)
          if (existingChat) {
            return {
              ...existingChat,
              ...newChat,
              privateChat: {
                ...existingChat.privateChat,
                ...newChat.privateChat,
                messages: existingChat.privateChat.messages || newChat.privateChat.messages,
              },
            }
          } else {
            return newChat
          }
        })

        const sortedUpdatedContent = updatedContent.slice().sort((a, b) => {
          const aLastMessageDate = a.lastMessage ? new Date(a.lastMessage.createdAt).getTime() : 0
          const bLastMessageDate = b.lastMessage ? new Date(b.lastMessage.createdAt).getTime() : 0
          return bLastMessageDate - aLastMessageDate
        })

        state.messagePrivetChats = {
          ...state.messagePrivetChats,
          ...action.payload,
          content: sortedUpdatedContent,
        }
      }
    },

    setMessagePrivetChat: (state, action: PayloadAction<PrivateChatViewResponseMe>) => {
      if (!state.messagePrivetChats || state.messagePrivetChats.content.length === 0) {
        state.messagePrivetChats = {
          content: [action.payload],
          token: undefined,
          size: 1,
          pageSize: 5555,
          isEnd: false,
        }
      } else {
        const newChat = action.payload
        // Find if the chat with the same ID already exists
        const existingChatIndex = state.messagePrivetChats.content.findIndex(e => e.privateChat.id === newChat.privateChat.id)
        if (existingChatIndex !== -1) {
          // If chat exists, replace it with the updated data
          state.messagePrivetChats.content[existingChatIndex] = {
            ...state.messagePrivetChats.content[existingChatIndex],
            privateChat: {
              ...state.messagePrivetChats.content[existingChatIndex].privateChat,
              ...newChat.privateChat, // Merge the existing and new privateChat data
              messages: newChat.privateChat.messages || state.messagePrivetChats.content[existingChatIndex].privateChat.messages, // Update messages if present
            },
            lastMessage: newChat.lastMessage || state.messagePrivetChats.content[existingChatIndex].lastMessage, // Update lastMessage if present
            unreadMessageCount: newChat.unreadMessageCount, // Update unreadMessageCount
          }
        } else {
          // If chat doesn't exist, add it as a new chat

          state.messagePrivetChats.content.push(newChat)
        }

        // Sort the chats by the createdAt date of the last message
        state.messagePrivetChats.content.sort((a, b) => {
          const aLastMessageDate = a.lastMessage ? new Date(a.lastMessage.createdAt).getTime() : 0
          const bLastMessageDate = b.lastMessage ? new Date(b.lastMessage.createdAt).getTime() : 0
          return bLastMessageDate - aLastMessageDate
        })

        // Update the size of the chats
        state.messagePrivetChats.size = state.messagePrivetChats.content.length
      }
    },

    deleteMessagePrivetChats: (state, action: PayloadAction<string>) => {
      if (state.messagePrivetChats) {
        const updatedContent = state.messagePrivetChats?.content.filter(chat => chat?.privateChat.id !== action.payload)

        state.messagePrivetChats = {
          ...state.messagePrivetChats,
          content: updatedContent,
        }
      }
    },
    addMatchingContent: (state, action: PayloadAction<{ matchingContent: any; privateChat: any }>) => {
      const { matchingContent, privateChat } = action.payload
      const chatToUpdate = state.messagePrivetChats?.content.find(chat => chat.privateChat.id === privateChat.id)

      if (chatToUpdate) {
        chatToUpdate.privateChat.matchingContent = matchingContent
        chatToUpdate.privateChat.chatTyping = false
      } else {
        // If the chat does not exist, optionally handle it (e.g., log a warning)
        console.warn(`Private chat with ID ${privateChat.id} not found`)
      }
    },

    setChatTyping: (state, action: PayloadAction<IChatTyping>) => {
      const { privateChatId, isActive } = action.payload.body

      const chatToUpdate = state.messagePrivetChats?.content.find(chat => chat.privateChat.id === privateChatId)
      if (chatToUpdate) {
        chatToUpdate.privateChat.chatTyping = isActive // Toggle the chatTyping state
      } else {
        // If the chat does not exist, optionally handle it (e.g., log a warning)
        console.warn(`Private chat with ID ${action.payload} not found`)
      }
    },
    getMessagePrivetChat: (state, action: PayloadAction<CursorPageMessageResponseMe>) => {
      const privateChatId = action.payload.content[0].privateChatId
      // Проверка на существование state.messagePrivetChats
      if (state.messagePrivetChats && state.messagePrivetChats.content) {
        const chatToUpdate = state.messagePrivetChats.content.find(e => e.privateChat.id === privateChatId)

        if (chatToUpdate) {
          // Проверка на существование chatToUpdate.privateChat.messages и его инициализация, если он отсутствует
          if (!chatToUpdate.privateChat.messages) {
            chatToUpdate.privateChat.messages = {
              content: [],
              token: undefined,
              privateChatId: '',
              size: 0,
              pageSize: 0,
              isEnd: false,
            }
          }

          // Проверка на существование chatToUpdate.privateChat.messages.content и его инициализация, если он отсутствует
          if (!chatToUpdate.privateChat.messages.content) {
            chatToUpdate.privateChat.messages.content = []
          }

          // Добавляем новые сообщения в существующий массив сообщений
          chatToUpdate.privateChat.messages.content.push(...action.payload.content)

          // Обновляем остальные параметры, кроме скролла
          chatToUpdate.privateChat.messages.token = action.payload.token
          chatToUpdate.privateChat.messages.privateChatId = action.payload.privateChatId
          chatToUpdate.privateChat.messages.size = action.payload.size
          chatToUpdate.privateChat.messages.pageSize = action.payload.pageSize
          chatToUpdate.privateChat.messages.isEnd = action.payload.isEnd
        }
      }
    },

    updateScrollPositionPrivetChat: (state, action: PayloadAction<any>) => {
      const privateChatId = action.payload.messagesSelectedId
      // Проверка на существование state.messagePrivetChats
      if (state.messagePrivetChats && state.messagePrivetChats.content) {
        const chatToUpdate = state.messagePrivetChats.content.find(e => e.privateChat.id === privateChatId)

        if (chatToUpdate && chatToUpdate.privateChat.messages) {
          // Обновляем параметр скролла
          chatToUpdate.privateChat.messages.scrollTop = action.payload.scrollTop
        }
      }
    },
    addMessagePrivetChat: (state, action: PayloadAction<IWebSocketMessageChat>) => {
      const newMessage = action.payload.body.message
      const privateChatId = action.payload.body.message?.privateChatId
      const needsReplacementId = 'pending' // Установите правильное значение идентификатора, если он фиксированный
      if (privateChatId && state.messagePrivetChats?.content) {
        const chatIndex = state.messagePrivetChats?.content.findIndex(chat => chat.privateChat.id === privateChatId)
        if (chatIndex !== -1 && chatIndex !== undefined) {
          // Обновить существующий чат
          const existingChat = state.messagePrivetChats!.content[chatIndex]

          // Инициализировать unreadMessageCount, если оно было неопределено
          if (existingChat.unreadMessageCount === undefined) {
            existingChat.unreadMessageCount = 0
          }
          // Проверка первого сообщения
          // @ts-ignore
          if (existingChat?.privateChat?.messages?.content?.length > 0) {
            // @ts-ignore
            const firstMessage = existingChat.privateChat.messages.content[0]
            if (firstMessage.id === needsReplacementId && action.payload.body.message?.senderRole === action.payload.role) {
              // @ts-ignore
              existingChat.privateChat.messages.content[0] = newMessage
            } else {
              if (existingChat.privateChat.approvedByModel === false && existingChat.privateChat.clientAttractAttentionCount === 0) {
                existingChat.privateChat.clientAttractAttentionCount = 1
                existingChat.privateChat.clientId = action?.payload?.body?.privateChat?.clientId
                existingChat.privateChat.modelId = action?.payload?.body?.privateChat?.modelId
                existingChat.privateChat.staffId = action?.payload?.body?.privateChat?.staffId
              }
              existingChat.privateChat.messages = {
                ...existingChat.privateChat.messages,
                content: [
                  // @ts-ignore
                  newMessage,
                  // @ts-ignore
                  ...existingChat.privateChat.messages.content,
                ],
              }
            }
          } else {
            existingChat.privateChat.messages = {
              ...existingChat.privateChat.messages,
              // @ts-ignore
              content: [newMessage],
            }
          }

          existingChat.lastMessage = newMessage

          if (
            action.payload.body.type === 'MESSAGE_CREATED' &&
            action.payload.role === 'CLIENT' &&
            action.payload.body.message?.isReadByClient === false
          ) {
            existingChat.unreadMessageCount += 1 // Увеличить unreadMessageCount
          }
          if (
            action.payload.body.type === 'MESSAGE_CREATED' &&
            action.payload.role === 'MODEL' &&
            action.payload.body.message?.isReadByModel === false
          ) {
            existingChat.unreadMessageCount += 1 // Увеличить unreadMessageCount
          }
          if (
            action.payload.body.type === 'MESSAGE_CREATED' &&
            action.payload.role === 'STAFF' &&
            action.payload.body.message?.isReadByStaff === false
          ) {
            existingChat.unreadMessageCount += 1 // Увеличить unreadMessageCount
          }

          state.messagePrivetChats!.content[chatIndex] = existingChat
        } else {
          // Создать новый чат
          debugger
          const newChat: PrivateChatViewResponseMe = {
            privateChat: {
              ...action.payload.body.privateChat,
              messages: {
                // @ts-ignore
                content: [newMessage],
                privateChatId,
                size: 1,
                pageSize: 10,
                isEnd: false,
              },
            },
            lastMessage: newMessage,
            unreadMessageCount: 1,
          }
          state.messagePrivetChats = {
            content: [newChat, ...(state.messagePrivetChats?.content || [])],
            token: state.messagePrivetChats?.token || undefined,
            size: (state.messagePrivetChats?.size || 0) + 1,
            pageSize: state.messagePrivetChats?.pageSize || 10,
            isEnd: state.messagePrivetChats?.isEnd || false,
          }
        }

        // Сортировка чатов по времени последнего сообщения
        state.messagePrivetChats!.content = sortChatsByLastMessage(state.messagePrivetChats!.content)
        state.fetchFrom = action.payload.body.createdAt
      }
    },

    addMessagePrivetChatMessagesError: (state, action: PayloadAction<{ privateChatId: string }>) => {
      const { privateChatId } = action.payload
      const needsReplacementId = 'pending'
      const errorId = 'error'

      if (privateChatId && state.messagePrivetChats?.content) {
        const chatIndex = state.messagePrivetChats?.content.findIndex(chat => chat.privateChat.id === privateChatId)

        if (chatIndex !== -1 && chatIndex !== undefined) {
          const existingChat = state.messagePrivetChats!.content[chatIndex]

          // Проверка первого сообщения
          // @ts-ignore
          if (existingChat?.privateChat?.messages?.content?.length > 0) {
            // @ts-ignore
            const firstMessage = existingChat.privateChat.messages.content[0]
            if (firstMessage.id === needsReplacementId) {
              firstMessage.id = errorId // Заменить идентификатор на 'error'
            }
          }

          state.messagePrivetChats!.content[chatIndex] = existingChat
        }
      }
    },
    markMessageAsRead: (state, action: PayloadAction<IWebSocketMessageChat>) => {
      const privateChatId = action.payload.body.message?.privateChatId
      if (privateChatId && state.messagePrivetChats?.content) {
        const chatIndex = state.messagePrivetChats?.content.findIndex(chat => chat.privateChat.id === privateChatId)
        const messageId = action.payload.body.messageId
        const userMeId = action.payload?.userMeId
        if (chatIndex !== undefined) {
          const existingChat = state.messagePrivetChats!.content[chatIndex]
          if (existingChat?.lastMessage?.id === action.payload.body.messageId) {
            existingChat.lastMessage = action.payload.body.message
          }
          if (existingChat) {
            if (action.payload?.body?.initiatorUserId !== userMeId && action.payload.body?.message?.type !== 'GENERAL') {
            } else {
              if (
                action.payload.body.type === 'MESSAGE_READ' &&
                action.payload.role === 'CLIENT' &&
                action.payload.userMeId !== action.payload.body?.message?.createdBy
              ) {
                existingChat.unreadMessageCount -= 1
              }
              if (
                action.payload.body.type === 'MESSAGE_READ' &&
                action.payload.role === 'MODEL' &&
                action.payload.userMeId !== action.payload.body.message?.createdBy
              ) {
                existingChat.unreadMessageCount -= 1
              }
              if (
                action.payload.body.type === 'MESSAGE_READ' &&
                action.payload.role === 'STAFF' &&
                action.payload.userMeId !== action.payload.body.message?.createdBy
              ) {
                existingChat.unreadMessageCount -= 1
              }
            }
          }
        }

        state.messagePrivetChats = {
          ...state.messagePrivetChats,
          // @ts-ignore
          content:
            state.messagePrivetChats?.content?.map(chat => {
              const updatedMessages = chat.privateChat.messages?.content?.map(msg =>
                msg.id === messageId
                  ? {
                      ...msg,
                      isReadByClient: true,
                      isReadByModel: true,
                      isReadByStaff: true,
                    }
                  : msg,
              )
              return {
                ...chat,
                privateChat: {
                  ...chat.privateChat,
                  messages: {
                    ...chat.privateChat.messages,
                    content: updatedMessages || chat.privateChat.messages?.content,
                  },
                },
              }
            }) || state.messagePrivetChats?.content,
        }
        state.fetchFrom = action.payload.body.createdAt
      }
    },
    clearMessagesPrivateChatClientAttractAttentionCount: (state, action: PayloadAction<string>) => {
      // Extract the chatId from the action payload
      const chatId = action.payload

      // Ensure content exists
      if (state.messagePrivetChats && state.messagePrivetChats.content) {
        // Find the index of the chat by id
        const chatIndex = state.messagePrivetChats.content.findIndex(chat => chat.privateChat.id === chatId)

        if (chatIndex !== -1) {
          // Reset the clientAttractAttentionCount
          state.messagePrivetChats.content[chatIndex].privateChat.clientAttractAttentionCount = 0
        }
      }
    },

    messagesInitialState: () => initialState,
  },
})

export default messagesSlice.reducer

export const {
  setMessagesSelectedId,
  changeClientsFetchAndBlock,
  changeModelsFetchAndBlock,
  setMessagesSelectedUser,
  messagesInitialState,
  getAllPrivateChatsSuccess,
  changeFullPrivateChats,
  setMessagePrivetChats,
  setMessagePrivetChat,
  addMessagePrivetChat,
  addMessagePrivetChatMessagesError,
  markMessageAsRead,
  getMessagePrivetChat,
  updateScrollPositionPrivetChat,
  addMatchingContent,
  setMessagesSelectedStaffTrue,
  setMessagesSelectedStaffFalse,
  setAdminSelectedChatType,
  deleteMessagePrivetChats,
  setChatTyping,
  sendMessages,
  clearMessagesPrivateChatClientAttractAttentionCount,
} = messagesSlice.actions
